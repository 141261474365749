html,
body {
    /*in next the target div is __next not root*/
    min-height: 100%;
    min-width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #f2f2f7;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&&family=Syncopate:wght@700&display=swap');

.text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

a {
    color: inherit;
    text-decoration: none;
}

.link {
    color: #0546af;
    cursor: pointer;
    text-decoration: underline;
}

.link:visited {
    color: #0648af;
}

.bold {
    font-weight: 700;
}

.bold700 {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.red {
    color: #f50057;
}

.yellow {
    color: #b2a300;
}

.blue {
    color: #2979ff;
}

.pulse-white {
    animation: pulse-white 3s infinite;
}

@keyframes pulse-white {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(41, 121, 255, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgb(41, 121, 255, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(41, 121, 255, 0);
    }
}
